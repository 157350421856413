/**
 * Mesh developers page
 *
 * @author Anja Bisako
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { Container } from '../components/Container';
import { Page } from '../components/Page';
import { HeaderH2, HeaderH3, LocalLink, WideBody } from '../components/Typography';
import { IndexLayout } from '../layouts';

const IndexPage: React.FC = () => {
	const { t } = useTranslation();

	return (
		<IndexLayout>
			<Page sectionBackground="lightBackground">
				<Container>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginTop: '2rem',
							marginBottom: '10rem',
						}}
					>
						<HeaderH2 style={{ marginBottom: '1rem' }}>{t('privacy.title')}</HeaderH2>

						<WideBody>
							Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen
							besonders hohen Stellenwert für die Geschäftsleitung der ThingOS GmbH & Co. KG. Eine
							Nutzung der Internetseiten der ThingOS GmbH & Co. KG ist grundsätzlich ohne jede
							Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere
							Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte,
							könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die
							Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche
							Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der
							betroffenen Person ein.
						</WideBody>

						<WideBody>
							Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift,
							E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang
							mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für die ThingOS
							GmbH geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser
							Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und
							Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
							informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung
							über die ihnen zustehenden Rechte aufgeklärt.
						</WideBody>

						<WideBody>
							Die ThingOS GmbH & Co. KG hat als für die Verarbeitung Verantwortlicher zahlreiche
							technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen
							Schutz der über diese Internetseite verarbeiteten personenbezogenen Daten
							sicherzustellen. Dennoch können Internetbasierte Datenübertragungen grundsätzlich
							Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden
							kann. Aus diesem Grund steht es jeder betroffenen Person frei, personenbezogene Daten
							auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.
						</WideBody>

						<HeaderH3 style={{ marginBottom: '1rem' }}>1. Begriffsbestimmungen</HeaderH3>
						<WideBody>
							Die Datenschutzerklärung der ThingOS GmbH & Co. KG beruht auf den Begrifflichkeiten,
							die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der
							Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung
							soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner
							einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die
							verwendeten Begrifflichkeiten erläutern.
						</WideBody>
						<WideBody>
							Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>a) Personenbezogene Daten</strong>
						</WideBody>
						<WideBody>
							Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder
							identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. Als
							identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
							insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
							zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen
							Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
							wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind,
							identifiziert werden kann.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>b) Betroffene Person</strong>
						</WideBody>
						<WideBody>
							Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person,
							deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet
							werden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>c) Verarbeitung</strong>
						</WideBody>
						<WideBody>
							Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte
							Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie
							das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die
							Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die
							Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung,
							den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die
							Vernichtung.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>d) Einschränkung der Verarbeitung</strong>
						</WideBody>
						<WideBody>
							Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener
							Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>e) Profiling</strong>
						</WideBody>
						<WideBody>
							Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die
							darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte
							persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
							insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
							persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder
							Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>f) Pseudonymisierung</strong>
						</WideBody>
						<WideBody>
							Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf
							welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht
							mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese
							zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
							organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen
							Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen
							werden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>
								g) Verantwortlicher oder für die Verarbeitung Verantwortlicher
							</strong>
						</WideBody>
						<WideBody>
							Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder
							juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
							mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
							entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
							das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise
							können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht
							der Mitgliedstaaten vorgesehen werden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>h) Auftragsverarbeiter</strong>
						</WideBody>
						<WideBody>
							Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung
							oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
							verarbeitet.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>i) Empfänger</strong>
						</WideBody>
						<WideBody>
							Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
							andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es
							sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines
							bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
							Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht
							als Empfänger.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>j) Dritter</strong>
						</WideBody>
						<WideBody>
							Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere
							Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und
							den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des
							Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>k) Einwilligung</strong>
						</WideBody>
						<WideBody>
							Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in
							informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer
							Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die
							betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
							betreffenden personenbezogenen Daten einverstanden ist.
						</WideBody>
						<HeaderH3>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</HeaderH3>
						<WideBody>
							Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den
							Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer
							Bestimmungen mit datenschutzrechtlichem Charakter ist die:
						</WideBody>
						<WideBody>
							ThingOS GmbH & Co. KG
							<br />
							Naststr. 15b
							<br />
							70184 Stuttgart
							<br />
							Deutschland
						</WideBody>
						<WideBody>
							Tel.: 071121952500
							<br />
							E-Mail: datenschutz@thingos.io
							<br />
							Website: www.thingos.io
						</WideBody>
						<HeaderH3>3. Cookies</HeaderH3>
						<WideBody>
							Die Internetseiten der ThingOS GmbH & Co. KG verwenden Cookies. Cookies sind
							Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt und
							gespeichert werden.
						</WideBody>
						<WideBody>
							Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine
							sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie
							besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten
							Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies
							ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der
							betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu
							unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID
							wiedererkannt und identifiziert werden.
						</WideBody>
						<WideBody>
							Durch den Einsatz von Cookies kann die ThingOS GmbH & Co. KG den Nutzern dieser
							Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung
							nicht möglich wären.
						</WideBody>
						<WideBody>
							Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite
							im Sinne des Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt,
							die Benutzer unserer Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist
							es, den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der Benutzer
							einer Internetseite, die Cookies verwendet, muss beispielsweise nicht bei jedem Besuch
							der Internetseite erneut seine Zugangsdaten eingeben, weil dies von der Internetseite
							und dem auf dem Computersystem des Benutzers abgelegten Cookie übernommen wird. Ein
							weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der Online-Shop
							merkt sich die Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat, über ein
							Cookie.
						</WideBody>
						<WideBody>
							Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite
							jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers
							verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Ferner können
							bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere
							Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern
							möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten
							Internetbrowser, sind unter Umständen nicht alle Funktionen unserer Internetseite
							vollumfänglich nutzbar.
						</WideBody>
						<HeaderH3>4. Erfassung von allgemeinen Daten und Informationen</HeaderH3>
						<WideBody>
							Die Internetseite der ThingOS GmbH & Co. KG erfasst mit jedem Aufruf der Internetseite
							durch eine betroffene Person oder ein automatisiertes System eine Reihe von
							allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden
							in den Logfiles des Servers gespeichert. Erfasst werden können die (1) verwendeten
							Browsertypen und Versionen, (2) das vom zugreifenden System verwendete Betriebssystem,
							(3) die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite
							gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes
							System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
							eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse
							(IP-Adresse), (7) der Internet-Service-Provider des zugreifenden Systems und (8)
							sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
							Angriffen auf unsere informationstechnologischen Systeme dienen.
						</WideBody>
						<WideBody>
							Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die ThingOS GmbH &
							Co. KG keine Rückschlüsse auf die betroffene Person. Diese Informationen werden
							vielmehr benötigt, um (1) die Inhalte unserer Internetseite korrekt auszuliefern, (2)
							die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren, (3) die
							dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der
							Technik unserer Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden
							im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen
							bereitzustellen. Diese anonym erhobenen Daten und Informationen werden durch die
							ThingOS GmbH & Co. KG daher einerseits statistisch und ferner mit dem Ziel
							ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu
							erhöhen, um letztlich ein optimales Schutzniveau für die von uns verarbeiteten
							personenbezogenen Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden
							getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten
							gespeichert.
						</WideBody>
						<HeaderH3>5. Abonnement unseres Newsletters</HeaderH3>
						<WideBody>
							Auf der Internetseite der ThingOS GmbH & Co. KG wird den Benutzern die Möglichkeit
							eingeräumt, den Newsletter unseres Unternehmens zu abonnieren. Welche
							personenbezogenen Daten bei der Bestellung des Newsletters an den für die Verarbeitung
							Verantwortlichen übermittelt werden, ergibt sich aus der hierzu verwendeten
							Eingabemaske.
						</WideBody>
						<WideBody>
							Die ThingOS GmbH & Co. KG informiert ihre Kunden und Geschäftspartner in regelmäßigen
							Abständen im Wege eines Newsletters über Angebote des Unternehmens. Der Newsletter
							unseres Unternehmens kann von der betroffenen Person grundsätzlich nur dann empfangen
							werden, wenn (1) die betroffene Person über eine gültige E-Mail-Adresse verfügt und
							(2) die betroffene Person sich für den Newsletterversand registriert. An die von einer
							betroffenen Person erstmalig für den Newsletterversand eingetragene E-Mail-Adresse
							wird aus rechtlichen Gründen eine Bestätigungsmail im Double-Opt-In-Verfahren
							versendet. Diese Bestätigungsmail dient der Überprüfung, ob der Inhaber der
							E-Mail-Adresse als betroffene Person den Empfang des Newsletters autorisiert hat.
						</WideBody>
						<WideBody>
							Bei der Anmeldung zum Newsletter speichern wir ferner die vom
							Internet-Service-Provider (ISP) vergebene IP-Adresse des von der betroffenen Person
							zum Zeitpunkt der Anmeldung verwendeten Computersystems sowie das Datum und die
							Uhrzeit der Anmeldung. Die Erhebung dieser Daten ist erforderlich, um den(möglichen)
							Missbrauch der E-Mail-Adresse einer betroffenen Person zu einem späteren Zeitpunkt
							nachvollziehen zu können und dient deshalb der rechtlichen Absicherung des für die
							Verarbeitung Verantwortlichen.
						</WideBody>
						<WideBody>
							Die im Rahmen einer Anmeldung zum Newsletter erhobenen personenbezogenen Daten werden
							ausschließlich zum Versand unseres Newsletters verwendet. Ferner könnten Abonnenten
							des Newsletters per E-Mail informiert werden, sofern dies für den Betrieb des
							Newsletter-Dienstes oder eine diesbezügliche Registrierung erforderlich ist, wie dies
							im Falle von Änderungen am Newsletterangebot oder bei der Veränderung der technischen
							Gegebenheiten der Fall sein könnte. Es erfolgt keine Weitergabe der im Rahmen des
							Newsletter-Dienstes erhobenen personenbezogenen Daten an Dritte. Das Abonnement
							unseres Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Die
							Einwilligung in die Speicherung personenbezogener Daten, die die betroffene Person uns
							für den Newsletterversand erteilt hat, kann jederzeit widerrufen werden. Zum Zwecke
							des Widerrufs der Einwilligung findet sich in jedem Newsletter ein entsprechender
							Link. Ferner besteht die Möglichkeit, sich jederzeit auch direkt auf der Internetseite
							des für die Verarbeitung Verantwortlichen vom Newsletterversand abzumelden oder dies
							dem für die Verarbeitung Verantwortlichen auf andere Weise mitzuteilen.
						</WideBody>

						<HeaderH3>6. Newsletter-Tracking</HeaderH3>
						<WideBody>
							Die Newsletter der ThingOS GmbH & Co. KG enthalten sogenannte Zählpixel. Ein Zählpixel
							ist eine Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im HTML-Format
							versendet werden, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu
							ermöglichen. Dadurch kann eine statistische Auswertung des Erfolges oder Misserfolges
							von Online-Marketing-Kampagnen durchgeführt werden. Anhand des eingebetteten
							Zählpixels kann die ThingOS GmbH & Co. KG erkennen, ob und wann eine E-Mail von einer
							betroffenen Person geöffnet wurde und welche in der E-Mail befindlichen Links von der
							betroffenen Person aufgerufen wurden.
						</WideBody>
						<WideBody>
							Solche über die in den Newslettern enthaltenen Zählpixel erhobenen personenbezogenen
							Daten, werden von dem für die Verarbeitung Verantwortlichen gespeichert und
							ausgewertet, um den Newsletterversand zu optimieren und den Inhalt zukünftiger
							Newsletter noch besser den Interessen der betroffenen Person anzupassen. Diese
							personenbezogenen Daten werden nicht an Dritte weitergegeben. Betroffene Personen sind
							jederzeit berechtigt, die diesbezügliche gesonderte, über das Double-Opt-In-Verfahren
							abgegebene Einwilligungserklärung zu widerrufen. Nach einem Widerruf werden diese
							personenbezogenen Daten von dem für die Verarbeitung Verantwortlichen gelöscht. Eine
							Abmeldung vom Erhalt des Newsletters deutet die ThingOS GmbH & Co. KG automatisch als
							Widerruf.
						</WideBody>
						<HeaderH3>7. Kontaktmöglichkeit über die Internetseite</HeaderH3>
						<WideBody>
							Die Internetseite der ThingOS GmbH & Co. KG enthält aufgrund von gesetzlichen
							Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserem
							Unternehmen sowie eine unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls
							eine allgemeine Adresse der sogenannten elektronischen Post (E-Mail-Adresse) umfasst.
							Sofern eine betroffene Person per E-Mail oder über ein Kontaktformular den Kontakt mit
							dem für die Verarbeitung Verantwortlichen aufnimmt, werden die von der betroffenen
							Person übermittelten personenbezogenen Daten automatisch gespeichert. Solche auf
							freiwilliger Basis von einer betroffenen Person an den für die Verarbeitung
							Verantwortlichen übermittelten personenbezogenen Daten werden für Zwecke der
							Bearbeitung oder der Kontaktaufnahme zur betroffenen Person gespeichert. Es erfolgt
							keine Weitergabe dieser personenbezogenen Daten an Dritte.
						</WideBody>
						<HeaderH3>8. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</HeaderH3>
						<WideBody>
							Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene
							Daten der betroffenen Person nur für den Zeitraum, der zur Erreichung des
							Speicherungszwecks erforderlich ist oder sofern dies durch den Europäischen
							Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
							Vorschriften, welchen der für die Verarbeitung Verantwortliche unterliegt, vorgesehen
							wurde.
						</WideBody>
						<WideBody>
							Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und
							Verordnungsgeber oder einem anderen zuständigen Gesetzgeber vorgeschriebene
							Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den
							gesetzlichen Vorschriften gesperrt oder gelöscht.
						</WideBody>
						<HeaderH3>9. Rechte der betroffenen Person</HeaderH3>
						<WideBody>
							<strong style={{ color: '#fff' }}>a) Recht auf Bestätigung</strong>
						</WideBody>
						<WideBody>
							Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber
							eingeräumte Recht, von dem für die Verarbeitung Verantwortlichen eine Bestätigung
							darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden.
							Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie
							sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
							wenden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>b) Recht auf Auskunft</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, jederzeit von dem für
							die Verarbeitung Verantwortlichen unentgeltliche Auskunft über die zu seiner Person
							gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten.
							Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person
							Auskunft über folgende Informationen zugestanden:
						</WideBody>
						<WideBody>
							<ul>
								<li>die Verarbeitungszwecke</li>
								<li>die Kategorien personenbezogener Daten, die verarbeitet werden</li>
								<li>
									die Empfänger oder Kategorien von Empfängern, gegenüber denen die
									personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden,
									insbesondere bei Empfängern in Drittländern oder bei internationalen
									Organisationen
								</li>
								<li>
									falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert
									werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung
									dieser Dauer
								</li>
								<li>
									das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden
									personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den
									Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung
								</li>
								<li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
								<li>
									wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden:
									Alle verfügbaren Informationen über die Herkunft der Daten
								</li>
								<li>
									das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
									gemäß Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen —
									aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und
									die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene
									Person
								</li>
							</ul>
						</WideBody>
						<WideBody>
							Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene
							Daten an ein Drittland oder an eine internationale Organisation übermittelt wurden.
							Sofern dies der Fall ist, so steht der betroffenen Person im Übrigen das Recht zu,
							Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu
							erhalten.
						</WideBody>
						<WideBody>
							Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich
							hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
							wenden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>c) Recht auf Berichtigung</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die unverzügliche
							Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner
							steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der
							Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten — auch
							mittels einer ergänzenden Erklärung — zu verlangen.
						</WideBody>
						<WideBody>
							Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie
							sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen
							wenden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>
								d) Recht auf Löschung (Recht auf Vergessen werden)
							</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
							Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen Daten
							unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit
							die Verarbeitung nicht erforderlich ist:
						</WideBody>
						<WideBody>
							<ul>
								<li>
									Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige
									Weise verarbeitet, für welche sie nicht mehr notwendig sind.
								</li>
								<li>
									Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung
									gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO
									stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
								</li>
								<li>
									Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die
									Verarbeitung ein, und es liegen keine vorrangigen berechtigten Gründe für die
									Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 DS-GVO
									Widerspruch gegen die Verarbeitung ein.
								</li>
								<li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
								<li>
									Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen
									Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
									erforderlich, dem der Verantwortliche unterliegt.
								</li>
								<li>
									Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
									Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
								</li>
							</ul>
						</WideBody>
						<WideBody>
							Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die
							Löschung von personenbezogenen Daten, die bei der ThingOS GmbH & Co. KG gespeichert
							sind, veranlassen möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des für
							die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der ThingOS GmbH & Co. KG
							wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.
						</WideBody>
						<WideBody>
							Wurden die personenbezogenen Daten von der ThingOS GmbH & Co. KG öffentlich gemacht
							und ist unser Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur
							Löschung der personenbezogenen Daten verpflichtet, so trifft die ThingOS GmbH & Co. KG
							unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten
							angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung
							Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten,
							darüber in Kenntnis zu setzen, dass die betroffene Person von diesen anderen für die
							Datenverarbeitung Verantwortlichen die Löschung sämtlicher Links zu diesen
							personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen
							Daten verlangt hat, soweit die Verarbeitung nicht erforderlich ist. Der Mitarbeiter
							der ThingOS GmbH & Co. KG wird im Einzelfall das Notwendige veranlassen.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>e) Recht auf Einschränkung der Verarbeitung</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, von dem
							Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine der
							folgenden Voraussetzungen gegeben ist:
						</WideBody>
						<WideBody>
							<ul>
								<li>
									Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person
									bestritten, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die
									Richtigkeit der personenbezogenen Daten zu überprüfen.
								</li>
								<li>
									Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der
									personenbezogenen Daten ab und verlangt stattdessen die Einschränkung der Nutzung
									der personenbezogenen Daten.
								</li>
								<li>
									Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der
									Verarbeitung nicht länger, die betroffene Person benötigt sie jedoch zur
									Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
								</li>
								<li>
									Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1
									DS-GVO eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des
									Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
								</li>
							</ul>
						</WideBody>
						<WideBody>
							Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person
							die Einschränkung von personenbezogenen Daten, die bei der ThingOS GmbH & Co. KG
							gespeichert sind, verlangen möchte, kann sie sich hierzu jederzeit an einen
							Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der
							ThingOS GmbH & Co. KG wird die Einschränkung der Verarbeitung veranlassen.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>f) Recht auf Datenübertragbarkeit</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, die sie betreffenden
							personenbezogenen Daten, welche durch die betroffene Person einem Verantwortlichen
							bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format
							zu erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen
							ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten
							bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung
							gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf
							einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung
							mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die
							Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder
							in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
						</WideBody>
						<WideBody>
							Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf
							Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die
							personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen
							Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern
							hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.
						</WideBody>
						<WideBody>
							Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person
							jederzeit an einen Mitarbeiter der ThingOS GmbH & Co. KG wenden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>g) Recht auf Widerspruch</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, aus Gründen, die sich
							aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie
							betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder
							f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese
							Bestimmungen gestütztes Profiling.
						</WideBody>
						<WideBody>
							Die ThingOS GmbH & Co. KG verarbeitet die personenbezogenen Daten im Falle des
							Widerspruchs nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für
							die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten der
							betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung,
							Ausübung oder Verteidigung von Rechtsansprüchen.
						</WideBody>
						<WideBody>
							Verarbeitet die ThingOS GmbH & Co. KG personenbezogene Daten, um Direktwerbung zu
							betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die
							Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen.
							Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung
							steht. Widerspricht die betroffene Person gegenüber der ThingOS GmbH & Co. KG der
							Verarbeitung für Zwecke der Direktwerbung, so wird die ThingOS GmbH & Co. KG die
							personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.
						</WideBody>
						<WideBody>
							Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen
							Situation ergeben, gegen die sie betreffende Verarbeitung personenbezogener Daten, die
							bei der ThingOS GmbH & Co. KG zu wissenschaftlichen oder historischen
							Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen,
							Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer
							im öffentlichen Interesse liegenden Aufgabe erforderlich.
						</WideBody>
						<WideBody>
							Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden
							Mitarbeiter der ThingOS GmbH & Co. KG oder einen anderen Mitarbeiter wenden. Der
							betroffenen Person steht es ferner frei, im Zusammenhang mit der Nutzung von Diensten
							der Informationsgesellschaft, ungeachtet der Richtlinie 2002/58/EG, ihr
							Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische
							Spezifikationen verwendet werden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>
								h) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling
							</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, nicht einer
							ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling —
							beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung
							entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die
							Entscheidung (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen
							der betroffenen Person und dem Verantwortlichen erforderlich ist, oder (2) aufgrund
							von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche
							unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur
							Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen
							Person enthalten oder (3) mit ausdrücklicher Einwilligung der betroffenen Person
							erfolgt.
						</WideBody>
						<WideBody>
							Ist die Entscheidung (1) für den Abschluss oder die Erfüllung eines Vertrags zwischen
							der betroffenen Person und dem Verantwortlichen erforderlich oder (2) erfolgt sie mit
							ausdrücklicher Einwilligung der betroffenen Person, trifft die ThingOS GmbH & Co. KG
							angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen
							der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des
							Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen
							Standpunkts und auf Anfechtung der Entscheidung gehört.
						</WideBody>
						<WideBody>
							Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen
							geltend machen, kann sie sich hierzu jederzeit an einen Mitarbeiter des für die
							Verarbeitung Verantwortlichen wenden.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>
								i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
							</strong>
						</WideBody>
						<WideBody>
							Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom
							Europäischen Richtlinien- und Verordnungsgeber gewährte Recht, eine Einwilligung zur
							Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
						</WideBody>
						<WideBody>
							Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen,
							kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
							Verantwortlichen wenden.
						</WideBody>
						<HeaderH3>10. Datenschutz bei Bewerbungen und im Bewerbungsverfahren</HeaderH3>
						<WideBody>
							Der für die Verarbeitung Verantwortliche erhebt und verarbeitet die personenbezogenen
							Daten von Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die
							Verarbeitung kann auch auf elektronischem Wege erfolgen. Dies ist insbesondere dann
							der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen
							Wege, beispielsweise per E-Mail oder über ein auf der Internetseite befindliches
							Webformular, an den für die Verarbeitung Verantwortlichen übermittelt. Schließt der
							für die Verarbeitung Verantwortliche einen Anstellungsvertrag mit einem Bewerber,
							werden die übermittelten Daten zum Zwecke der Abwicklung des
							Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert.
							Wird von dem für die Verarbeitung Verantwortlichen kein Anstellungsvertrag mit dem
							Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei Monate nach Bekanntgabe
							der Absageentscheidung automatisch gelöscht, sofern einer Löschung keine sonstigen
							berechtigten Interessen des für die Verarbeitung Verantwortlichen entgegenstehen.
							Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine Beweispflicht
							in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).
						</WideBody>
						<HeaderH3>11. Rechtsgrundlage der Verarbeitung</HeaderH3>
						<WideBody>
							Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für
							Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten
							Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur
							Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist,
							erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für
							eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder
							Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO.
							Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher
							Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zur unseren Produkten oder
							Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche
							eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise
							zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c
							DS-GVO. In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten
							erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer
							anderen natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein
							Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter,
							seine Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein
							Krankenhaus oder sonstige Dritte weitergegeben werden müssten. Dann würde die
							Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen.
						</WideBody>
						<WideBody>
							Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO beruhen. Auf dieser
							Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten
							Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten
							Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die
							Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
							Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den
							Europäischen Gesetzgeber besonders erwähnt wurden. Er vertrat insoweit die Auffassung,
							dass ein berechtigtes Interesse anzunehmen sein könnte, wenn die betroffene Person ein
							Kunde des Verantwortlichen ist (Erwägungsgrund 47 Satz 2 DS-GVO).
						</WideBody>
						<HeaderH3>12. Berechtigte Interessen an der Verarbeitung</HeaderH3>
						<WideBody>
							Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f DS-GVO ist
							unser berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des
							Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.
						</WideBody>
						<HeaderH3>13. Dauer der Speicherung</HeaderH3>
						<WideBody>
							Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die
							jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die
							entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur
							Vertragserfüllung oder Vertragsanbahnung erforderlich sind.
						</WideBody>
						<HeaderH3>
							14. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
							personenbezogenen Daten
						</HeaderH3>
						<WideBody>
							Wir klären Sie darüber auf, dass die Bereitstellung personenbezogener Daten zum Teil
							gesetzlich vorgeschrieben ist (z.B. Steuervorschriften) oder sich auch aus
							vertraglichen Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.
						</WideBody>
						<WideBody>
							Mitunter kann es zu einem Vertragsschluss erforderlich sein, dass eine betroffene
							Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge durch uns
							verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns
							personenbezogene Daten bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag
							abschließt. Eine Nichtbereitstellung der personenbezogenen Daten hätte zur Folge, dass
							der Vertrag mit dem Betroffenen nicht geschlossen werden könnte.
						</WideBody>
						<WideBody>
							Vor einer Bereitstellung personenbezogener Daten durch den Betroffenen muss sich der
							Betroffene an einen unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den
							Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der personenbezogenen
							Daten gesetzlich oder vertraglich vorgeschrieben oder für den Vertragsabschluss
							erforderlich ist, ob eine Verpflichtung besteht, die personenbezogenen Daten
							bereitzustellen, und welche Folgen die Nichtbereitstellung der personenbezogenen Daten
							hätte.
						</WideBody>
						<HeaderH3>15. Bestehen einer automatisierten Entscheidungsfindung</HeaderH3>
						<WideBody>
							Als verantwortungsbewusstes Unternehmen verzichten wir auf eine automatische
							Entscheidungsfindung oder ein Profiling.
						</WideBody>
						<WideBody>
							Diese Datenschutzerklärung wurde durch den Datenschutzerklärungs-Generator der DGD
							Deutsche Gesellschaft für Datenschutz GmbH, die als{' '}
							<LocalLink href="https://dg-datenschutz.de/datenschutz-dienstleistungen/externer-datenschutzbeauftragter/">
								Externer Datenschutzbeauftragter Bayreuth
							</LocalLink>{' '}
							tätig ist, in Kooperation mit dem{' '}
							<LocalLink href="https://www.wbs-law.de/it-recht/datenschutzrecht/">
								Anwalt für IT- und Datenschutzrecht
							</LocalLink>{' '}
							Christian Solmecke erstellt.
						</WideBody>
						<HeaderH3>Google Analytics</HeaderH3>
						<WideBody>
							Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist
							die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google
							Analytics verwendet bei uns keine sogenannte “Cookies” und keinerlei personenbezogene
							Daten.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>IP-Anonymisierung</strong>
						</WideBody>
						<WideBody>
							Wir haben auf dieser Webseite die Funktion IP-Anonymisierung aktiviert. Dadurch wird
							Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder
							in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der
							Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
							einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
							Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
							der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und
							um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
							gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
							Ihrem Browser übermittelte IP- Adresse wird nicht mit anderen Daten von Google
							zusammengeführt.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>Browser-Plug-in</strong>
						</WideBody>
						<WideBody>
							Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
							Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
							gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
							können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf
							Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
							Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden
							Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
							<LocalLink href="https://tools.google.com/dlpage/gaoptout?hl=de">
								https://tools.google.com/dlpage/gaoptout?hl=de
							</LocalLink>
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>Widerspruch gegen Datenerfassung</strong>
						</WideBody>
						<WideBody>
							Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf
							folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer
							Daten bei zukünftigen Besuchen dieser Website verhindert:{' '}
							<LocalLink>Google Analytics deaktivieren</LocalLink>
						</WideBody>
						<WideBody>
							Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
							Datenschutzerklärung von Google:{' '}
							<LocalLink href="https://support.google.com/analytics/answer/6004245?hl=de">
								https://support.google.com/analytics/answer/6004245?hl=de
							</LocalLink>
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>Auftragsdatenverarbeitung</strong>
						</WideBody>
						<WideBody>
							Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und
							setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von
							Google Analytics vollständig um.
						</WideBody>
						<WideBody>
							<strong style={{ color: '#fff' }}>Demografische Merkmale bei Google Analytics</strong>
						</WideBody>
						<WideBody>
							Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics.
							Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und
							Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener
							Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können
							keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über
							die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer
							Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung”
							dargestellt generell untersagen.
						</WideBody>
					</div>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default IndexPage;
